import type { FormatterMap, ProductRoutesOptions, RouteList } from '@grandvisionhq/frk-routing'
import { createSimpleFormatter } from '@grandvisionhq/frk-routing'
import {
  createAuthFormatters,
  createCheckoutFormatters,
  createMyAccountFormatters,
  productDetailFormatters,
  productListFormatter,
} from '@grandvisionhq/www-next/formatters'

const productRoutesOptions: ProductRoutesOptions = {
  accessories: 'zubehoer',
  'contact-lenses': 'kontaktlinsen',
  frames: 'brillen',
  'ready-readers': 'brillen/lesebrillen',
  solutions: 'kontaktlinsen/pflegemittel',
  sunglasses: 'sonnenbrillen',
}

export const localRouteFormatters: FormatterMap = {
  home: createSimpleFormatter('/'),
  search: createSimpleFormatter('/search'),
  'cart.overview': createSimpleFormatter('cart'),
  'download.invoice': createSimpleFormatter('download-invoice'),
  'express-overview': createSimpleFormatter('express-overview'),
  'appointments.book': createSimpleFormatter('terminbuchung'),
  'appointments.cancel': createSimpleFormatter('cancel-appointment'),
  'appointments.reschedule': createSimpleFormatter('reschedule-appointment'),
  ...createMyAccountFormatters(),
  ...createAuthFormatters(),
  'express-checkout': createSimpleFormatter('express-checkout'),
  'order.confirm': createSimpleFormatter('order/confirm'),
  ...createCheckoutFormatters({
    path: 'checkout',
    children: { shipping: 'versand', overview: 'zusammenfassung', payment: 'zahlung' },
  }),
  ...productListFormatter(productRoutesOptions),
  ...productDetailFormatters(productRoutesOptions),
  wishlist: createSimpleFormatter('/wishlist'),
  returns: createSimpleFormatter('/returns'),
  'returns.select-items': createSimpleFormatter('/returns/select-items'),
  'returns.confirmation': createSimpleFormatter('/returns/confirmation'),
  'cancel.subscription': createSimpleFormatter('/cancel-subscription'),
}

export const routes: RouteList = [
  ['/', 'home'],

  ['/checkout/versand', 'checkout.shipping'],
  ['/checkout/zusammenfassung', 'checkout.overview'],
  ['/checkout/zahlung', 'checkout.payment'],
  ['/api/payment-callback', 'payment.callback'],
  ['/order/confirm', 'order.confirm'],
  ['/express-overview', 'express-overview'],

  ['/express-overview', 'express-overview'],
  ['/terminbuchung/(.*)?', 'appointments.book'],
  ['/terminbuchung-stornieren', 'appointments.cancel'],
  ['/termin-verschieben-termin', 'appointments.reschedule'],
  ['/abo-kuendigen', 'cancel.subscription'],
]
